import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getTypeShiftByEmployee(employeeId) {
  const response = await apiService.get(`/tipos-de-turno`, {
    params: {
      id_trabajador: employeeId,
    },
  });
  return response;
}
