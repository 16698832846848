// slices
import appConfigReducer from './slices/appConfig';
import initSettingsReducer from './slices/initSettings';
import unitsReducer from './slices/units';
import detImprovementsReducer from './slices/detImprovements';
import userPermissionsReducer from './slices/userPermissions';
import bulkLeavesReducer from '../views/leave/bulk-leaves/redux/slices/bulkLeaves';
import documentValidationProcessReducer from '../views/document-management/document-validator/redux/slices/documentValidationProcess'

// ----------------------------------------------------------------------

export const getReducers = () => ({
  appConfig: appConfigReducer,
  units: unitsReducer,
  initSettings: initSettingsReducer,
  userPermissions: userPermissionsReducer,
  detImprovements: detImprovementsReducer,
  bulkLeaves: bulkLeavesReducer,
  documentValidationProcess: documentValidationProcessReducer,
});
