const sateliteTheme = {
  palette: {
    text: {
      primary: '#00518E',
      dark: '#171A50',
      disabled: '#999999'
    },
    primary: {
      dark: '#001861',
    },
    background: {
      default: '#FFF4ED',
    },
  },
  component: {
    navigation: {
      toolbar: {
        background: '#001861',
        boxShadow: 'none',
        button: {
          text: {
            primary: '#fff',
            secondary: '#A7A4A4',
          },
          hover: {
            background: '#1F3475',
          },
        },
        popover: {
          item: {
            hover: {
              background: '#e0f4fc',
            },
            text: {
              primary: '#00518E',
            },
          },
        },
        logo: {
          width: '250px',
        },
      },
      drawer: {
        background: '#001861',
        item: {
          text: {
            primary: '#fff',
          },
          hover: {
            background: '#1F3475',
          },
          active: {
            background: '#6674A1',
          },
        },
        footer: {
          button: {
            background: '#AD2349',
            text: {
              primary: '#fff',
            },
            hover: {
              background: '#900C3F',
            },
          },
          text: {
            primary: '#fff',
            secondary: '#A7A4A4',
          },
        },
        colapse: {
          background: '#001861',
          boxShadow: 'rgba(70, 84, 187, 0.24) 0px 2px 7px',
          text: {
            primary: '#D9D0F4',
            secondary: '#565383',
          },
        },
      },
    },
    button: {
      primary: {
        contained: {
          background: '#FF8E4D',
          text: {
            primary: '#fff',
          },
          hover: {
            background: '#FE8A48',
          },
          active: {
            background: '#FF8E4D',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        outlined: {
          borderColor: '#FF8E4D',
          text: {
            primary: '#FF8E4D',
          },
          hover: {
            background: '#FFF4ED',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },        
        text: {
          text: {
            primary: '#FF8E4D',
          },
          hover: {
            background: '#FFF4ED',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
      },
      secondary: {
        contained: {
          background: '#B76E00',
          text: {
            primary: '#fff',
          },
          hover: {
            background: '#A76400',
          },
          active: {
            background: '#B76E00',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        outlined: {
          borderColor: '#B76E00',
          text: {
            primary: '#B76E00',
          },
          hover: {
            background: '#B76E0020',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        text: {
          text: {
            primary: '#B76E00',
          },
          hover: {
            background: '#B76E0020',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
      },
    },
    customDialog: {
      header: {
        background: '#001861',
        text: {
          primary: '#fff',
        },
      },
      tableData: {
        text: {
          primary: '#00518E',
        },
      },
    },
    tab: {
      header: {
        background: '#FFF4ED',
        text: {
          primary: '#00518E',
          secondary: '#5A5A5A',
        },
        indicador: '#00518E',
      },
    },
    dataTable: {
      odd: '#FFF4ED',
      text: {
        primary: '#00518E',
      },
    },
    select: {
      item: {
        text: {
          primary: '#00518E',
        },
        hover: {
          background: '#FFF4ED',
        },
        selected: {
          background: '#ffe7d8',
        },
      },
    },
    alert: {
      error: {
        background: '#B71D1820',
        text: {
          primary: '#BF2D24',
        },
      },
      info: {
        background: '#006FD320',
        text: {
          primary: '#4654BB',
        },
      },
      success: {
        background: '#1DFFA020',
        text: {
          primary: '#1E6849',
        },
      },
      warning: {
        background: '#B76E0020',
        text: {
          primary: '#B76E00',
        },
      },
    },
    checkbox: {
      text: {
        primary: '#4654BB',
      },
    },
    iconButton: {
      background: '#fff',
      boxShadow: '0px 4px 4px 0px #00000040',
      color: '#FE8A48',
    },
    accordion: {
      summary: {
        text: {
          primary: '#00518E',
        },
        expanded: {
          background: '#ffe7d8',
        },
        hover: {
          background: '#FFF4ED',
        },
      },
    },
    tooltip: {
      primary: '#001861',
    },
    chip: {
      success: {
        background: '#1DFFA020',
        text: {
          primary: '#2A9066',
        },
      },
      warning: {
        background: '#B76E0020',
        text: {
          primary: '#B76E00',
          },
      },
      error: {
        background: '#B71D1820',
        text: {
          primary: '#B71D18',
        },
      },
    }
  },
  view: {
    login: {
      text: {
        primary: '#00518E',
      },
      button: {
        background: 'linear-gradient(90deg, #1B1800 0%, #1E1492 100%)',
        hover: {
          background: 'linear-gradient(90deg, #001861 0%, #1E1492 100%)',
        },
        active: {
          background: 'linear-gradient(90deg, #1B1800 0%, #1E1492 100%)',
        },
      },
      logo: {
        height: 'auto',
      },
    },
    multifactor: {
      text: {
        primary: '#00518E',
        secondary: '#FF8E4D',
      },
      linearProgress: {
        background: '#f7f5f5',
        bar: '#00518E',
      },
    },
    report: {
      card: {
        background: '#001861',
        hover: {
          background: '#282D5F',
        },
        icon: {
          background: 'linear-gradient(180deg, #282D5F 0%, #3D448D 100%)',
        },
        text: {
          primary: '#fff',
        },
      },
    },
  },
};

export default sateliteTheme;
