import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requestData: [],
  filteredUnits: [],
  positionOptions: [],
  shiftTypeSelected: '',
  unitsSelected: [],
  positionsSelected: [],
  filteredWorkers: [],
  selectedWorkers: [],
  selectedDate: new Date(),
  selectedOptions: {
    direction: '',
    isFixedTime: true,
    limitTime: '',
    leaveType: '',
  },
  formStep: 1,
  isLoading: false,
};

const slice = createSlice({
  name: 'bulkLeaves',
  initialState,
  reducers: {
    setRequestData(state, action) {
      state.requestData = action.payload;
    },
    setFilteredUnits(state, action) {
      state.filteredUnits = action.payload;
    },
    setPositionOptions(state, action) {
      state.positionOptions = action.payload;
    },
    setShiftTypeSelected(state, action) {
      state.shiftTypeSelected = action.payload;
    },
    setUnitsSelected(state, action) {
      state.unitsSelected = action.payload;
    },
    setPositionsSelected(state, action) {
      state.positionsSelected = action.payload;
    },
    setFilteredWorkers(state, action) {
      state.filteredWorkers = action.payload;
    },
    setSelectedWorkers(state, action) {
      state.selectedWorkers = action.payload;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setSelectedOptions(state, action) {
      state.selectedOptions = action.payload;
    },
    setFormStep(state, action) {
      state.formStep = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

// Actions
export const {
  setRequestData,
  setFilteredUnits,
  setPositionOptions,
  setShiftTypeSelected,
  setUnitsSelected,
  setPositionsSelected,
  setFilteredWorkers,
  setSelectedWorkers,
  setSelectedDate,
  setSelectedOptions,
  setFormStep,
  setIsLoading,
} = slice.actions;

// Methods
export function cleanBulkLeavesFormData() {
  return (dispatch) => {
    dispatch(slice.actions.setFormStep(1));
    dispatch(slice.actions.setRequestData([]));
    dispatch(slice.actions.setSelectedWorkers([]));
    dispatch(slice.actions.setUnitsSelected([]));
    dispatch(slice.actions.setPositionsSelected([]));
    dispatch(slice.actions.setShiftTypeSelected(''));
    dispatch(slice.actions.setSelectedDate(new Date()));
  };
}
// Reducer
export default slice.reducer;
