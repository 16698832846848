import apiService from '../../utils/getApi';

const sendUserConfirmation = async (usuario) => {
  const response = await apiService.post('/usuario/codigoConfirmacion', {
    usuario,
  });
  return response;
};

export default sendUserConfirmation;
