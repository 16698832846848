import _ from 'lodash';

export const getPositionsAssignedUnits = (unitList) =>
  _.uniqBy(
    _.flatMap(unitList, (unit) => _.get(unit, 'lista_det_puesto')),
    'iddetPuesto'
  );

export const sortPositionsByName = (positions, keyPositionName = 'nombreDetPuesto', sort = 'asc') =>
  _.orderBy(positions, keyPositionName, sort);

export const getListPostUnitsUserConfig = (unitList) =>
  sortPositionsByName(
    _.map(getPositionsAssignedUnits(unitList), (position) => ({
      ...position,
      nombreDetPuesto: _.upperCase(position.nombreDetPuesto),
    }))
  );

export const getPositionsAssociatedWithUnits = (
  unitsSelected,
  positionsSelected,
  units,
  keyUnitId = 'idunidad',
  keyPositionList = 'lista_det_puesto',
  keyDetPositionId = 'iddetPuesto'
) => {
  const asociatedPosition = [];

  positionsSelected.forEach((positionId) => {
    unitsSelected.forEach((unitId) => {
      const unit = units.find((unit) => unit[keyUnitId] === unitId);

      const detPositionList = unit[keyPositionList];

      if (detPositionList && detPositionList.length > 0) {
        detPositionList.forEach((detPuesto) => {
          if (detPuesto[keyDetPositionId] === positionId) {
            const existDepPuesto = asociatedPosition.find((adp) => adp === positionId);

            if (!existDepPuesto) {
              asociatedPosition.push(positionId);
            }
          }
        });
      }
    });
  });

  return asociatedPosition;
};

export const positionSelectedByUnit = (unit, positionIds, type, uniqueType) => {
  const listPositions = _.unionBy(
    _.flatMap(unit.lista_det_puesto, (detPuesto) => {
      if (type === 'rflex') {
        return detPuesto;
      }

      return _.flatMap(detPuesto.puesto_pnet, (puesto) => {
        puesto.detPuesto = detPuesto;
        return puesto;
      });
    }),
    uniqueType
  );

  return _.filter(listPositions, (position) => _.includes(positionIds, position.iddetPuesto));
};

export const handleListPositionsByType = (units, type, uniqType) => {
  const listPositions = _.orderBy(
    _.uniqBy(
      _.flatMap(units, (el) => {
        if (type === 'rflex') {
          return el.lista_det_puesto;
        }

        return _.flatMap(el.lista_det_puesto, (detPuesto) =>
          generateListPositions(detPuesto.puesto_pnet, detPuesto.iddetPuesto)
        );
      }),
      uniqType
    ),
    uniqType,
    'asc'
  );

  return listPositions;
};

/**
 * Devuelve los puestos homologados (detPuesto) que están asociadas a los puestos de integración.
 * @param {Array<Number>} positionsIds
 * @param {Array<Object>} detPositions
 * @returns {Array<Object>}
 */
export const getDetPositionsFromIntegrationPositions = (positionsIds, detPositions) => (
  detPositions.reduce((acc, detPosition) => {
    const position = detPosition.puesto_pnet.find((puesto) => positionsIds.includes(puesto.puesto_idpuesto));
    if (position) {
      acc.push({
        iddetPuesto: detPosition.iddetPuesto,
        nombreDetPuesto: detPosition.nombreDetPuesto,
      });
    }
    return acc;
  }, [])
);

const generateListPositions = (listPositions, idPosition) =>
  _.map(listPositions, (objPosition) => {
    objPosition.puesto.iddetPuesto = idPosition;
    return objPosition.puesto;
  });
