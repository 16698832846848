const rflexTheme = {
  palette: {
    text: {
      primary: '#303980',
      dark: '#171A50',
      disabled: '#999999'
    },
    primary: {
      dark: '#53285f',
    },
    background: {
      default: '#F6F6FF',
    },
  },
  component: {
    navigation: {
      toolbar: {
        background: '#53285f',
        boxShadow: '0px 4px 10px rgba(83, 40, 95, 0.3)',
        button: {
          text: {
            primary: '#fff',
            secondary: '#8B529B',
          },
          hover: {
            background: '#c700ff20',
          },
        },
        popover: {
          item: {
            hover: {
              background: '#f9e5ff',
            },
            text: {
              primary: '#171A50',
            },
          },
        },
        logo: {
          width: '114px',
        },
      },
      drawer: {
        background: '#F6F6FF',
        item: {
          text: {
            primary: '#171A50',
          },
          hover: {
            background: '#F9E5FF',
          },
          active: {
            background: '#c700ff20',
          },
        },
        footer: {
          button: {
            background: '#2A9066',
            text: {
              primary: '#fff',
            },
            hover: {
              background: '#1E6849',
            },
          },
          text: {
            primary: '#171A50',
            secondary: '#A7A4A4',
          },
        },
        colapse: {
          background: '#F6F6FF',
          boxShadow: 'rgba(83, 40, 95, 0.3) 0px 2px 7px',
          text: {
            primary: '#53285F',
          },
        },
      },
    },
    button: {
      primary: {
        contained: {
          background: '#4654BB',
          text: {
            primary: '#fff',
          },
          hover: {
            background: '#303980',
          },
          active: {
            background: '#4654BB',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        outlined: {
          borderColor: '#4654BB',
          text: {
            primary: '#4654BB',
          },
          hover: {
            background: '#F6F6FF',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        text: {
          text: {
            primary: '#4654BB',
          },
          hover: {
            background: '#F6F6FF',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
      },
      secondary: {
        contained: {
          background: '#B76E00',
          text: {
            primary: '#fff',
          },
          hover: {
            background: '#A76400',
          },
          active: {
            background: '#B76E00',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        outlined: {
          borderColor: '#B76E00',
          text: {
            primary: '#B76E00',
          },
          hover: {
            background: '#B76E0020',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
        text: {
          text: {
            primary: '#B76E00',
          },
          hover: {
            background: '#B76E0020',
          },
          fontSize: '14px',
          borderRadius: '20px',
          height: '36px',
        },
      },
    },
    customDialog: {
      header: {
        background: '#53285F',
        text: {
          primary: '#fff',
        },
      },
      tableData: {
        text: {
          primary: '#171A50',
        },
      },
    },
    tab: {
      header: {
        background: '#F6F6FF',
        text: {
          primary: '#171A50',
          secondary: '#A7A4A4',
        },
        indicador: '#171A50',
      },
    },
    dataTable: {
      odd: '#F6F6FF',
      text: {
        primary: '#171A50',
      },
    },
    select: {
      item: {
        text: {
          primary: '#171A50',
        },
        hover: {
          background: '#F6F6FF',
        },
        selected: {
          background: '#DFE0FF',
        },
      },
    },
    alert: {
      error: {
        background: '#B71D1820',
        text: {
          primary: '#BF2D24',
        },
      },
      info: {
        background: '#006FD320',
        text: {
          primary: '#4654BB',
        },
      },
      success: {
        background: '#1DFFA020',
        text: {
          primary: '#1E6849',
        },
      },
      warning: {
        background: '#B76E0020',
        text: {
          primary: '#B76E00',
        },
      },
    },
    checkbox: {
      text: {
        primary: '#303980',
      },
    },
    iconButton: {
      background: '#fff',
      boxShadow: '0px 4px 4px 0px #00000040',
      color: '#303980',
    },
    accordion: {
      summary: {
        text: {
          primary: '#303980',
        },
        expanded: {
          background: '#DFE0FF',
        },
        hover: {
          background: '#F6F6FF',
        },
      },
    },
    tooltip: {
      primary: '#4654BB',
    },
    chip: {
      success: {
        background: '#1DFFA020',
        text: {
          primary: '#2A9066',
        },
      },
      warning: {
        background: '#B76E0020',
        text: {
          primary: '#B76E00',
          },
      },
      error: {
        background: '#B71D1820',
        text: {
          primary: '#B71D18',
        },
      },
    }
  },
  view: {
    login: {
      text: {
        primary: '#79318D',
      },
      button: {
        background: 'linear-gradient(90deg, #320343 0%, #92148D 100%)',
        hover: {
          background: 'linear-gradient(90deg, #76059D 0%, #92148D 100%)',
        },
        active: {
          background: 'linear-gradient(90deg, #320343 0%, #92148D 100%)',
        },
      },
      logo: {
        height: '85px',
      },
    },
    multifactor: {
      text: {
        primary: '#171A50',
        secondary: '#4654BB',
      },
      linearProgress: {
        background: '#f9dffe',
        bar: '#8D4F8C',
      },
    },
    report: {
      card: {
        background: '#53285F',
        hover: {
          background: '#733782',
        },
        icon: {
          background: 'linear-gradient(180deg, #53285F 0%, #7C3D8D 100%)',
        },
        text: {
          primary: '#fff',
        },
      },
    },
  },
};

export default rflexTheme;
