import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// api
import { getUnitUnlinked, getUnits } from '../../api';
import { handleListPositionsByType } from '../../utils/positions';
import { TYPE_STATUS_UNIT, UNIT_TYPES } from '../../constantes/app';
import { esGerencia } from '../../utils/session';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  units: [],
  unitUnlinked: {},
  detPositions: [],
  positions: [],
  rFlexPositions: [],
};

const slice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setUnits(state, action) {
      state.units = action.payload;
      state.isLoading = false;
    },

    setUnitUnlinked(state, action) {
      state.unitUnlinked = action.payload;
      state.isLoading = false;
    },

    setDetPositions(state, action) {
      state.detPositions = action.payload;
      state.isLoading = false;
    },

    setPositions(state, action) {
      state.positions = action.payload;
      state.isLoading = false;
    },

    setRFlexPositions(state, action) {
      state.rFlexPositions = action.payload;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUnits, setPositions, setRFlexPositions } = slice.actions;

// ----------------------------------------------------------------------

export function requestUnits() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await getUnits();
      handleSetPositions(data, dispatch);
      dispatch(slice.actions.setUnits(data.sort((a, b) => a.nombreUnidad.localeCompare(b.nombreUnidad))));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestUnitUnlinked() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await getUnitUnlinked();
      dispatch(slice.actions.setUnitUnlinked(_.first(data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFilteredUnits() {
  const filteredUnits = (dispatch, getState) => {
    const { units } = getState();

    return units.units.filter((el) => el.lista_det_puesto.length > 0 && el.tipoEstadoUnidad_idtipoEstadoUnidad !== null);
  };

  return filteredUnits;
}

// Función que obtiene unidades filtradas por estado y tipo de unidad
export function getUnitsFilteredByStateAndType() {
  const unitsFilteredByUnitState = (dispatch, getState) => {
    // Obtiene el estado actual de las unidades desde el store
    const { units } = getState();

    // Extrae el tipo de unidad interna y el estado de unidad desde las constantes
    const { unidadInterna } = UNIT_TYPES;
    const { unidadPagando } = TYPE_STATUS_UNIT;

    // Define la condición inicial para el filtrado
    const condicion = {
      tipoUnidad_idtipoUnidad: unidadInterna
    };

    // Si el usuario tiene permisos de gerencia, añade una condición adicional
    if (esGerencia()) {
      condicion.tipoEstadoUnidad_idtipoEstadoUnidad = unidadPagando;
    }

    // Verifica que todas las propiedades en la condición coincidan
    const filteredUnits = units.units.filter((el) =>
      Object.keys(condicion).every((key) => el[key] === condicion[key])
    );

    return filteredUnits;
  };

  return unitsFilteredByUnitState;
}


const handleSetPositions = (units, dispatch) => {
  if (units.length > 0) {
    const detPuestos = _.flatMap(units, 'lista_det_puesto');

    const rFlexListPositions = handleListPositionsByType(units, 'rflex', 'nombreDetPuesto');
    const integrationListPositions = handleListPositionsByType(units, 'integration', 'NOMBREPUESTO');

    dispatch(
      slice.actions.setDetPositions(_.uniqBy(detPuestos, 'iddetPuesto'))
    );

    dispatch(
      slice.actions.setPositions(
        integrationListPositions.map((el) => ({
          value: el.IDPUESTO,
          label: el.NOMBREPUESTO,
          detPuesto: el.iddetPuesto, // TODO: Refactorizar por: detPositionId: el.iddetPuesto
        }))
      )
    );

    dispatch(
      slice.actions.setRFlexPositions(
        rFlexListPositions.map((el) => ({
          value: el.iddetPuesto,
          label: el.nombreDetPuesto,
          detPuesto: el,
        }))
      )
    );
  }
};